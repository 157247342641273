import * as React from 'react';
import * as CardModal from 'src/components/CardModal';
import * as Constants from 'src/constants';
import Button from 'src/components/Button';
import AuthOptionStep from './AuthOptionStep';
import OtpStep from './OtpStep';
import PinStep from './PinStep';
import { StyleSheet } from 'react-native';
import { useRouter } from 'next/router';

interface PropsIface {
  phone: string;
  redirectUrl?: string;
}

const LoginButton: React.FC<PropsIface> = (props) => {
  const router = useRouter();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<number>(0);
  const [method, setMethod] = React.useState<number>(0);

  const onLoginPress = React.useCallback(() => {
    setStep(0);
    setModalOpen(true);
  }, []);

  const onNextPress = React.useCallback(() => {
    if (step >= 1) {
      router.push(props.redirectUrl != null ? decodeURIComponent(props.redirectUrl) : '/u/settings');
    } else {
      setStep((step) => step + 1);
    }
  }, [step, props.redirectUrl]);

  const onBackPress = React.useCallback(() => {
    setStep((step) => step - 1);
  }, []);

  React.useEffect(() => {
    if (step < 0 || step > 1) {
      setModalOpen(false);
    }
  }, [step]);

  return (
    <>
      <Button disabled={props.phone.length !== 10} type='secondary' onPress={onLoginPress}>
        {'Log in'}
      </Button>
      {modalOpen ? (
        <CardModal.Portal style={styles.cardModal}>
          {step === 0 ? (
            <AuthOptionStep
              phone={props.phone}
              method={method}
              onMethodChange={setMethod}
              onNext={onNextPress}
              onBack={onBackPress}
            />
          ) : step === 1 ? (
            method === 0 ? (
              <OtpStep phone={props.phone} onSuccess={onNextPress} onBack={onBackPress} />
            ) : (
              <PinStep phone={props.phone} onSuccess={onNextPress} onBack={onBackPress} />
            )
          ) : null}
        </CardModal.Portal>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: Constants.Grid.dp(480),
    maxWidth: '100%',
  },
  logoImage: {
    width: Constants.Grid.dp(175),
    height: Constants.Grid.dp(36),
  },
  actionRow: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  cardModal: {
    width: Constants.Grid.dp(480),
  },
});

export default LoginButton;
