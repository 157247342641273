import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import InnerPortal from 'src/components/Portal';
import { ActivityIndicator, Image, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

export const Body: React.FC<{}> = (props) => {
  return <Layout.EdgeGutter style={styles.bodyRoot}>{props.children}</Layout.EdgeGutter>;
};

interface HeaderPropsIface {
  errorLabel?: string;
  nextLabel?: string;
  nextDisabled?: boolean;
  onNext?: () => Promise<void>;
  onBack?: () => void;
}

export const Header: React.FC<HeaderPropsIface> = (props) => {
  const [nextLoading, setNextLoading] = React.useState<boolean>(false);

  const backImageSource = React.useMemo(() => {
    return {
      uri: '/static/images/app/navigation-back.png',
      width: Constants.Grid.dp(24),
      height: Constants.Grid.dp(24),
    };
  }, []);

  const onNext = React.useCallback(async () => {
    if (props.onNext != null) {
      setNextLoading(true);
      try {
        await props.onNext();
      } catch (e) {
      } finally {
        setNextLoading(false);
      }
    }
  }, [props.onNext]);

  return (
    <>
      <Layout.EdgeGutter style={styles.navigationRow}>
        <TouchableOpacity onPress={props.onBack}>
          <Image
            source={backImageSource}
            width={Constants.Grid.dp(24)}
            height={Constants.Grid.dp(24)}
            style={imageStyles.backIcon}
            resizeMode='contain'
          />
        </TouchableOpacity>
        <Button
          disabled={nextLoading || props.nextDisabled}
          type='primary'
          onPress={onNext}
          leftAdornment={nextLoading ? <ActivityIndicator size='small' style={styles.leftAdornment} /> : undefined}
        >
          {props.nextLabel ?? 'Next'}
        </Button>
      </Layout.EdgeGutter>
      {props.errorLabel != null ? (
        <Layout.EdgeGutter style={styles.errorContainer}>
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CWhite]}>{props.errorLabel}</Text>
        </Layout.EdgeGutter>
      ) : null}
    </>
  );
};

interface PortalPropsIface {
  style?: StyleProp<ViewStyle>;
}

export const Portal: React.FC<PortalPropsIface> = (props) => {
  return (
    <InnerPortal id='modal-card'>
      <View style={styles.background} />
      <View style={[styles.root, props.style]}>{props.children}</View>
    </InnerPortal>
  );
};

const styles = StyleSheet.create({
  background: {
    // @ts-ignore: (RNW CSS)
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 999,
    width: '100%',
    height: '100%',
    opacity: 0.5,
    backgroundColor: Constants.NewColor.Black,
  },
  root: {
    // @ts-ignore: (RNW CSS)
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: 999,
    maxWidth: '90%',
    maxHeight: '90%',
    backgroundColor: Constants.NewColor.White,
    paddingTop: Constants.Grid.dp(12),
    paddingBottom: Constants.Grid.dp(48),
    borderRadius: Constants.Grid.dp(12),
    transform: [
      {
        // @ts-ignore: (RNW CSS)
        translateX: '-50%',
      },
      {
        // @ts-ignore: (RNW CSS)
        translateY: '-50%',
      },
    ],
  },
  navigationRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: Constants.Grid.dp(12),
  },
  navigationRowLeftColumn: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftAdornment: {
    marginRight: Constants.Grid.dp(6),
  },
  errorContainer: {
    paddingVertical: Constants.Grid.dp(12),
    backgroundColor: Constants.NewColor.AccentRed,
  },
  bodyRoot: {
    marginTop: Constants.Grid.dp(12),
  },
});

const imageStyles = StyleSheet.create({
  backIcon: {
    width: Constants.Grid.dpFromPx(24),
    height: Constants.Grid.dpFromPx(24),
    tintColor: Constants.NewColor.Black,
  },
});
