import * as React from 'react';
import * as CardModal from 'src/components/CardModal';
import * as Constants from 'src/constants';
import Text from 'src/components/Text';
import TextInput from 'src/components/TextInput';
import { useConfirmPinState } from './useConfirmPinState';

interface PropsIface {
  phone: string;
  onSuccess: () => void;
  onBack: () => void;
}

const PinStep: React.FC<PropsIface> = (props) => {
  const [code, setCode] = React.useState<string>('');
  const confirmPinState = useConfirmPinState({
    phone: props.phone,
    code,
    onSuccess: props.onSuccess,
    onBack: props.onBack,
  });

  return (
    <>
      <CardModal.Header
        errorLabel={confirmPinState.errorMessage ?? undefined}
        onNext={confirmPinState.onNextPress}
        onBack={confirmPinState.onBackPress}
      />
      <CardModal.Body>
        <Text style={Constants.TextStyle.T24B}>{'6-digit PIN code'}</Text>
        <Text style={[Constants.TextStyle.T16R, Constants.TextStyle.CDarkGray, Constants.GridStyle.MBUnit]}>
          {`If you already set up a OneShop PIN code for ${props.phone}, you can use it to log in here.`}
        </Text>
        <TextInput
          autoFocus
          autoComplete='tel'
          style={Constants.TextStyle.T16R}
          placeholder='Code'
          keyboardType='number-pad'
          textContentType='oneTimeCode'
          onChangeText={setCode}
          maxLength={6}
        />
      </CardModal.Body>
    </>
  );
};

export default PinStep;
