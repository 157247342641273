import { useRouter } from 'next/router';
import * as React from 'react';

const PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

enum ErrorCode {
  PhoneNumberRequired,
  UnexpectedError,
  InvalidPhoneNumber,
  InvalidCountryCode,
}

const FormattedError: {
  [key in ErrorCode]: string;
} = {
  [ErrorCode.PhoneNumberRequired]: 'A phone number is required to log in.',
  [ErrorCode.UnexpectedError]: 'Something is wrong – please try again later.',
  [ErrorCode.InvalidPhoneNumber]: 'That’s not a valid phone number.',
  [ErrorCode.InvalidCountryCode]: 'That’s not a valid country code.',
};

interface Args {
  phone: string;
  onBack: () => void;
  onSuccess: () => void;
}

interface Response {
  errorCode: null | ErrorCode;
  errorMessage: null | string;
  onNextPress: () => Promise<void>;
  onBackPress: () => void;
}

export const useLoginStartState = (args: Args): Response => {
  const router = useRouter();
  const [errorCode, setErrorCode] = React.useState<null | ErrorCode>(null);

  const debug = router.query['debug'] != null;

  const onNextPress = React.useCallback(async () => {
    if (!PHONE_REGEX.test(args.phone)) {
      setErrorCode(ErrorCode.InvalidPhoneNumber);
      return;
    }

    setErrorCode(null);

    try {
      const loginResponse = await fetch(`https://api.porter.oneshop.com/api/business_manager/login/request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          countryCode: '+1',
          subscriberNumber: args.phone,
          debug: debug ? '1' : process.env.NEXT_PUBLIC_DEBUG === '1' ? '1' : undefined,
        }),
      });
      if (loginResponse.status === 200) {
        args.onSuccess();
      } else if (loginResponse.status === 404) {
        setErrorCode(ErrorCode.UnexpectedError);
      } else if (Math.floor(loginResponse.status / 100) === 4) {
        setErrorCode(ErrorCode.InvalidPhoneNumber);
      } else {
        setErrorCode(ErrorCode.UnexpectedError);
      }
    } catch (e) {
      setErrorCode(ErrorCode.UnexpectedError);
    }
  }, [args.phone, debug]);

  const onBackPress = React.useCallback(() => {
    setErrorCode(null);
    args.onBack();
  }, [args.onBack]);

  return {
    errorCode,
    errorMessage: errorCode != null ? FormattedError[errorCode] : null,
    onNextPress,
    onBackPress,
  };
};
