import * as React from 'react';
import * as Constants from 'src/constants';
import { SelectedIndexContext, OnSelectedIndexChangedContext } from '../context';
import { StyleSheet, TouchableOpacity } from 'react-native';

interface PropsIface {
  idx: number;
}

export const Button: React.FC<PropsIface> = (props) => {
  const selectedIndex = React.useContext(SelectedIndexContext);
  const onSelectedIndexChanged = React.useContext(OnSelectedIndexChangedContext);
  const onPress = React.useCallback(() => {
    onSelectedIndexChanged(props.idx);
  }, [props.idx, onSelectedIndexChanged]);
  return (
    <TouchableOpacity style={[styles.root, props.idx === selectedIndex && styles.selected]} onPress={onPress}>
      {props.children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    borderRadius: Constants.Grid.dp(16),
    paddingVertical: Constants.Grid.dp(26),
    paddingHorizontal: Constants.Grid.dp(16),
  },
  selected: {
    backgroundColor: Constants.Color.LightShade,
  },
});
