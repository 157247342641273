import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import Text from 'src/components/Text';
import TextInput from 'src/components/TextInput';
import CreateButton from './CreateButton';
import LoginButton from './LoginButton';
import { useRouter } from 'next/router';
import { Image, StyleSheet, View } from 'react-native';

const LoginScreen: React.FC<{}> = () => {
  const [phone, setPhone] = React.useState<string>('');
  const router = useRouter();
  const redirectUrl = Array.isArray(router.query.redirect) ? router.query.redirect[0] : router.query.redirect;

  const logoImageSource = React.useMemo(() => {
    return {
      uri: '/static/images/app/logo-login.png?v=1',
      width: Constants.Grid.dp(176),
      height: Constants.Grid.dp(36),
    };
  }, []);

  const onChangePhoneText = React.useCallback((text: string) => {
    setPhone(text.replace(/[^0-9]/g, ''));
  }, []);

  return (
    <View style={styles.root}>
      <Layout.EdgeGutter style={styles.card}>
        <View style={Constants.GridStyle.MB2Unit}>
          <Image
            source={logoImageSource}
            width={Constants.Grid.dp(175)}
            height={Constants.Grid.dp(36)}
            style={styles.logoImage}
            resizeMode='contain'
          />
        </View>
        <View style={Constants.GridStyle.MB2Unit}>
          <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MB2Unit]}>
            {'Welcome back! Log in to your existing OneShop account.'}
          </Text>
          <Text style={[Constants.TextStyle.T12M, Constants.GridStyle.MBUnit]}>{'Mobile phone number'}</Text>

          <TextInput
            autoFocus
            autoComplete='tel'
            style={Constants.TextStyle.T16R}
            placeholder='Your phone number'
            keyboardType='number-pad'
            textContentType='telephoneNumber'
            value={phone}
            onChangeText={onChangePhoneText}
            maxLength={'(XXX) XXX-XXXX'.length}
          />
        </View>
        <LoginButton phone={phone} redirectUrl={redirectUrl} />
      </Layout.EdgeGutter>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: Constants.Grid.dp(360),
    maxWidth: '100%',
  },
  logoImage: {
    width: Constants.Grid.dp(175),
    height: Constants.Grid.dp(36),
  },
  actionRow: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  cardModal: {
    width: Constants.Grid.dp(480),
  },
});

export default LoginScreen;
