import * as React from 'react';
import * as CardModal from 'src/components/CardModal';
import * as Constants from 'src/constants';
import * as LargeSingleSelect from 'src/components/LargeSingleSelect';
import Text from 'src/components/Text';
import { useLoginStartState } from './useLoginStartState';

interface PropsIface {
  phone: string;
  method: number;
  onMethodChange: (method: number) => void;
  onNext: () => void;
  onBack: () => void;
}

const AuthOptionStep: React.FC<PropsIface> = (props) => {
  const loginStartState = useLoginStartState({
    phone: props.phone,
    onSuccess: props.onNext,
    onBack: props.onBack,
  });

  const onNext = React.useCallback(async () => {
    if (props.method === 0) {
      loginStartState.onNextPress();
    } else {
      props.onNext();
    }
  }, [props.method, loginStartState.onNextPress]);

  return (
    <>
      <CardModal.Header
        errorLabel={loginStartState.errorMessage ?? undefined}
        onNext={onNext}
        onBack={loginStartState.onBackPress}
      />
      <CardModal.Body>
        <Text style={[Constants.TextStyle.T24B, Constants.GridStyle.MBUnit]}>{'How do you want to log in?'}</Text>
        <LargeSingleSelect.Provider defaultSelectedIdx={props.method} onSelectedIdxChanged={props.onMethodChange}>
          <LargeSingleSelect.Button idx={0}>
            <Text style={Constants.TextStyle.T16M}>{`Send a code to ${props.phone}`}</Text>
          </LargeSingleSelect.Button>
          <LargeSingleSelect.Button idx={1}>
            <Text style={Constants.TextStyle.T16M}>{'Use your OneShop PIN code'}</Text>
          </LargeSingleSelect.Button>
        </LargeSingleSelect.Provider>
      </CardModal.Body>
    </>
  );
};

export default AuthOptionStep;
