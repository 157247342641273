import * as React from 'react';
import { SelectedIndexContext, OnSelectedIndexChangedContext } from '../context';

interface PropsIface {
  defaultSelectedIdx: number;
  onSelectedIdxChanged?: (idx: number) => void;
}

export const Provider: React.FC<PropsIface> = (props) => {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(props.defaultSelectedIdx);
  React.useEffect(() => {
    if (props.onSelectedIdxChanged != null) {
      props.onSelectedIdxChanged(selectedIndex);
    }
  }, [selectedIndex, props.defaultSelectedIdx, props.onSelectedIdxChanged]);
  return (
    <SelectedIndexContext.Provider value={selectedIndex}>
      <OnSelectedIndexChangedContext.Provider value={setSelectedIndex}>
        {props.children}
      </OnSelectedIndexChangedContext.Provider>
    </SelectedIndexContext.Provider>
  );
};
