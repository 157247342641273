import Head from 'next/head';
import Screen from 'src/views/auth/LoginScreen';

export default function Example() {
  return (
    <>
      <Head>
        <title>Login | OneShop</title>
        <meta key='og-image' property='og:image' content='https://tools.oneshop.com/static/images/og-image.png?v=4' />
      </Head>
      <Screen />
    </>
  );
}
