import * as React from 'react';
import * as Network from 'src/clients/Network';
import * as SecureStore from 'src/clients/SecureStore';

enum ErrorCode {
  UnexpectedError,
  NotRegistered,
  InvalidPhoneNumberOrToken,
}

const FormattedError: {
  [key in ErrorCode]: string;
} = {
  [ErrorCode.UnexpectedError]: 'Either your code or phone number might be incorrect.',
  [ErrorCode.NotRegistered]: 'Not registered. Did you mean to sign up?',
  [ErrorCode.InvalidPhoneNumberOrToken]: 'Either your code or phone number is incorrect.',
};

interface Args {
  code: string;
  phone: string;
  onBack: () => void;
  onSuccess: () => void;
}

interface Response {
  errorCode: null | ErrorCode;
  errorMessage: null | string;
  onNextPress: () => Promise<void>;
  onBackPress: () => void;
}

export const useConfirmPinState = (args: Args): Response => {
  const [errorCode, setErrorCode] = React.useState<null | ErrorCode>(null);

  const onNextPress = React.useCallback(async () => {
    setErrorCode(null);

    try {
      const response = await Network.http.fetch('/api/business_manager/login/verify', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          countryCode: '+1',
          subscriberNumber: args.phone,
          pin: args.code,
        }),
      });
      if (response.status === 200) {
        setTimeout(() => {
          const secureStore = SecureStore.get([SecureStore.Key.BusinessManagerId, SecureStore.Key.BusinessId]);

          const businessId = secureStore['x-sp-b-id'] ?? null;

          try {
            // @ts-ignore
            if (window.fbq != null && businessId != null) {
              // @ts-ignore
              window.fbq('init', '752587695479733', {
                ph: `1${args.phone}`,
                external_id: businessId,
              });
            }
          } catch (err: any) {
            console.error(err);
          }

          try {
            // @ts-ignore
            if (window.gtag != null) {
              if (businessId != null) {
                // @ts-ignore
                window.gtag('config', 'GTM-KRX8Z9W', {
                  user_id: businessId,
                });
              }
              // @ts-ignore
              window.gtag('event', 'login');
            }
          } catch (err: any) {
            console.error(err);
          }
        }, 30);
        args.onSuccess();
      } else if (response.status === 404) {
        setErrorCode(ErrorCode.NotRegistered);
      } else if (response.status === 401 || response.status === 205) {
        setErrorCode(ErrorCode.InvalidPhoneNumberOrToken);
      } else {
        setErrorCode(ErrorCode.UnexpectedError);
      }
    } catch (e) {
      setErrorCode(ErrorCode.UnexpectedError);
    }
  }, [args.phone, args.code, args.onSuccess]);

  const onBackPress = React.useCallback(() => {
    setErrorCode(null);
    args.onBack();
  }, [args.onBack]);

  return {
    errorCode,
    errorMessage: errorCode != null ? FormattedError[errorCode] : null,
    onNextPress,
    onBackPress,
  };
};
